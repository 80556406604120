import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'

import { Action } from './types'
import { IntegrationDebugModal } from './integration-debug-modal'

/**
 * @deprecated refer to the new integration-debug-modal.tsx which is react specific, without dependency on events.
 * Do not use this component as reference, it will be removed when angular task list is decomissioned.
 */

export function IntegrationDebugModalFromEvent() {
  const [eventId, setEventId] = useState<string | undefined>(undefined)

  const closeModal = () => {
    setEventId(undefined)
  }

  const openIntegrationDebugModal = async ({ action }: { action: Action }) => {
    setEventId(action.eventId)
  }

  useEffect(() => {
    eventManager.on('open-integration-debug-modal', openIntegrationDebugModal)

    return () => {
      eventManager.off('open-integration-debug-modal', openIntegrationDebugModal)
    }
  }, [])

  useEffect(() => {}, [eventId])

  return <>{eventId && <IntegrationDebugModal eventId={eventId} onDebugClose={closeModal} />}</>
}
