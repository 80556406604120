import { DashboardMediaType, TimeIntervalType } from '../types'

export const INTERVAL_FORMAT_CODE_MAP: Record<TimeIntervalType, string> = {
  month: 'MMMM'
}

// the print and email sizes seem arbitrary. I'm not sure how to compute those based on
// known values, but those or close to those work. The -16 is a guess because that seems to
// work and is twice the horizontal spacing
export const getWidgetWidth = (media: DashboardMediaType, fullWidth: boolean) => {
  let nonScreenWidth: number | undefined

  switch (media) {
    case 'screen':
      return fullWidth ? '100%' : '50%'
    case 'print':
      nonScreenWidth = fullWidth ? 900 : 450
      break
    case 'email':
      nonScreenWidth = fullWidth ? 550 : 275
      break
    default:
      return '100%'
  }

  return nonScreenWidth - 16
}
