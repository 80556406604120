import * as yup from 'yup'
import { keyBy } from 'lodash'

import { CustomField, CustomFieldDisplayType, FieldValue } from 'main/services/queries/types'

// with named groups to break down matches - https://regexr.com/8dpd2
// (?<opentag><[^>]+>)(?<emptyspace>\s*)(?<required_content>[^<\s]+)(?<anything>[\s\S]*)(?<closetag><\/[^>]+>)

export const TEXTAREA_FIELD_HAS_CONTENT_REGEX = /[^>]+>\s*[^<\s]+[\s\S]*<\/[^>]+>/

export const customFieldValidation = (
  customFieldsLookup: Record<CustomField['id'], CustomField>,
  validateRequired: boolean,
  validateArchived: boolean
) =>
  yup.lazy((fieldValues: Record<CustomField['id'], FieldValue> = {}) => {
    const cfValidationEntries = Object.keys(fieldValues).reduce((acc, cfId) => {
      const cf = customFieldsLookup[cfId as unknown as number] as CustomField
      const fieldOptionLookup = keyBy(cf.field_options, 'id')
      const cfRequired = !validateRequired ? false : cf?.required ?? false

      if (cf?.archived) {
        return acc
      }
      const cfSlug =
        cf.type === 'SearchableCustomField' || cf.type === 'MultiSearchableCustomField'
          ? 'searchable'
          : cf.field_type.slug
      switch (cfSlug) {
        case 'text':
        case 'datetime':
        case 'code_editor':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .string()
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required()
                })
                .nullable()
            })
          }
        case 'searchable':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .array()
                .when([], {
                  is: () => cfRequired,
                  then: schema =>
                    schema.required().test('valid-array', 'Must contain at least 1 valid item', value => {
                      if (!Array.isArray(value) || value.length === 0) {
                        return false
                      }
                      // Ensure at least one object isnt set to destroy
                      return value.some(item => item && item.destroy !== true)
                    })
                })
                .nullable()
            })
          }
        case 'textarea':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup.string().when([], {
                is: () => cfRequired,
                then: schema =>
                  schema
                    .test('has-content', 'Must contain at least 1 non-empty element', value => {
                      if (!value) return false

                      // we use the faster regex check first because we run this on every key stroke for form
                      // *re*validation (default RTL's mode). This mode enables the error messaging on the field
                      // to disappear when the field becomes valid after changing the invalid content.
                      const regexTestResult = TEXTAREA_FIELD_HAS_CONTENT_REGEX.test(value)
                      if (regexTestResult) return true

                      // the regex doesn't catch all valid cases, so we use the slower DOM parser check
                      // as a backup check
                      const parser = new DOMParser()
                      const result = parser.parseFromString(value, 'text/html')
                      return !!result.body.innerText?.trim()
                    })
                    .required()
              })
            })
          }
        case 'select_menu':
        case 'radiobox':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              field_option_id: yup
                .number()
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required()
                })
                .test('no-archived-fields', function (value) {
                  if (!value || !validateArchived) {
                    return true
                  }

                  if (fieldOptionLookup[value].archived) {
                    return this.createError({
                      message: JSON.stringify({
                        archived: value
                      })
                    })
                  }

                  return true
                })
                .nullable()
            })
          }

        case 'checkboxes':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .string()
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required() // TODO: Ensure at least one selected option
                })
                .test('no-archived-fields', function (value) {
                  if (!value || !validateArchived) {
                    return true
                  }

                  let parsedValue
                  try {
                    parsedValue = JSON.parse(value)
                  } catch (e) {
                    return this.createError({
                      message: 'Invalid input format',
                      params: { cfId, value }
                    })
                  }

                  const invalidIds: number[] = parsedValue.filter(
                    (fieldOptionId: number) => fieldOptionLookup[fieldOptionId]?.archived
                  )

                  if (invalidIds.length > 0) {
                    return this.createError({
                      message: JSON.stringify({
                        archived: invalidIds
                      })
                    })
                  }

                  return true
                })
                .nullable()
            })
          }
        case 'task_picker':
        case 'user_select':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .string()
                // .matches(/\[\]/) TODO: matches a stringified array of numbers, min length 0
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required() // TODO: matches a stringified array of numbers, min length 1
                })
                .nullable()
            })
          }
        case 'temporary':
        case 'endpoint':
          return acc

        default:
          return unhandledCFTypeError(cf.field_type.slug)
      }
    }, {})

    return yup.object().shape(cfValidationEntries)
  })

const unhandledCFTypeError = (type: CustomFieldDisplayType): never => {
  throw new Error(
    `Custom field type ${type} not validated. If this is expected please handle it by returning the accumulator`
  )
}
