import { useCallback, useMemo, useState } from 'react'

import { IconButton, LoadingPanel } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FolderArchiveModal } from '../modals/folder-archive-modal'
import { useFilters, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { FormEditPanel } from 'main/components/shared/form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import { Account, FolderEditFolderPayload, FolderGetFolder, FolderListFolder } from 'main/services/queries/types'
import { useFolder, useFolderEdit } from 'main/services/queries/use-folders'
import { FolderEditForm, FolderEditFormType, validationSchema } from './folder-edit-form'

export const FolderEditPanel = () => {
  const [{ folderId, folders }, { closeRightPanel }] = useRightPanelTypeState('folder-edit')

  if (!folderId) return null
  return <FolderEditPanelWrapper folderId={folderId} onClose={closeRightPanel} folders={folders} />
}

type FolderEditPanelWrapperProps = {
  folderId: number
  folders?: FolderListFolder[]
  onClose: () => void
}

const FolderEditPanelWrapper = ({ folderId, ...folderEditProps }: FolderEditPanelWrapperProps) => {
  const { data, isLoading, isError } = useFolder(folderId)
  const { account } = useAccount()

  if (!account || isError || isLoading || !data) return <LoadingPanel />
  return <FolderEdit account={account} folder={data.project} {...folderEditProps} />
}

type FolderEditProps = {
  folders?: FolderListFolder[]
  folder: FolderGetFolder
  onClose: () => void
  account: Account
}

export const FolderEdit = ({ folder, folders = [], onClose, account }: FolderEditProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'folder.editPanel' })
  const { filters } = useFilters()
  const setFilterParams = useSetFilterParams()

  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const { mutateAsync } = useFolderEdit(folder.id)
  const handleSubmit = async (data: FolderEditFolderPayload) => mutateAsync(data)

  const canEdit = useMemo(() => !!folder?.permissions?.['update']?.[0], [folder])
  const canDelete = useMemo(() => !!folder?.permissions?.['destroy']?.[0], [folder])

  const defaultValues = { ...(folder ?? {}) }

  const handleArchive = useCallback(() => {
    const currentProjectIdIndex = Array.isArray(filters?.project) && filters.project.findIndex(id => id === folder.id)
    if (currentProjectIdIndex === false) return onClose()

    // Clear current id from browser params
    const newProjects = [...((filters?.project as string[]) || [])]
    newProjects.splice(currentProjectIdIndex, 1)
    setFilterParams({ project: newProjects })
    onClose()
  }, [filters.project, onClose, setFilterParams, folder.id])

  return (
    <>
      <FolderArchiveModal
        open={showArchiveModal}
        setOpen={setShowArchiveModal}
        folderId={folder.id}
        folderName={folder.name}
        onSuccess={handleArchive}
        folders={folders}
        hasRunbooks={!!folder.runbooks_count}
      />
      <FormEditPanel<FolderEditFormType, FolderEditFolderPayload>
        onClose={onClose}
        onSubmit={handleSubmit}
        transformer={dataTransformer}
        defaultValues={defaultValues}
        successMessage={t('successMessage')}
        readOnly={!canEdit}
        disabled={!canEdit}
        headerItems={
          canDelete
            ? [
                <IconButton
                  label="Archive"
                  tipPlacement="top"
                  icon="trash-o"
                  onClick={() => setShowArchiveModal(true)}
                />
              ]
            : undefined
        }
        title={t('title')}
        schema={validationSchema}
      >
        <FolderEditForm folder={folder} folders={folders} account={account} />
      </FormEditPanel>
    </>
  )
}

const dataTransformer = (data: FolderEditFormType): FolderEditFolderPayload => {
  const { role_types, ...form } = data
  return {
    ...form,
    roles:
      role_types
        ?.map(role =>
          (role.users || []).map(user => ({
            id: user.role_id,
            resource_id: form.id,
            resource_type: 'Project',
            role_type_id: role.id,
            subject_id: user.id,
            subject_type: 'User'
          }))
        )
        .flat() ?? []
  }
}
