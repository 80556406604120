import { createElement, Fragment } from 'react'
import { CustomLayerProps, Line, LineSvgProps, Point, ResponsiveLine, Serie } from '@nivo/line'
import { format, toDate } from 'date-fns'

import { Box, ChartAxesLayer, ChartBackgroundRowsLayer, nivoTheme, resolveColor, useTheme } from '@cutover/react-ui'
import { DashboardMediaType, PlannedVsActualDurationComponent } from 'main/components/dashboards/widgets/types'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { DataPoint, usePlannedVsActualDurationChartData } from './use-planned-vs-actual-duration-chart-data'
import { getWidgetWidth } from '../../account/helpers'

type PlannedVsActualDurationWidgetProps = {
  media: DashboardMediaType
  data: PlannedVsActualDurationComponent
}

export const PlannedVsActualDurationWidget = ({ data, media }: PlannedVsActualDurationWidgetProps) => {
  if (data.values.length === 0) return <></> // does not display the widget

  const getChartDataFunction = usePlannedVsActualDurationChartData
  const {
    lineChartData,
    plannedDone,
    actualDone,
    activePointsToDisplayFilledCircle,
    maxDuration,
    xMinValue,
    xMaxValue
  } = getChartDataFunction(data.values)

  const isDurationInHours = maxDuration > 15 * 60
  const shadedArea = createShadedArea(plannedDone, actualDone)
  const Points = createPoints(activePointsToDisplayFilledCircle)

  const chartProps: LineSvgProps = {
    margin: {
      top: 10,
      right: 35,
      bottom: 25,
      left: 65
    },
    data: lineChartData,
    xScale: {
      type: 'time',
      useUTC: false
    },
    yScale: {
      type: 'linear',
      max: maxDuration,
      min: 0
    },
    theme: nivoTheme,
    layers: [ChartBackgroundRowsLayer, ChartAxesLayer, 'axes', DashedLine, 'markers', shadedArea, Points],
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickValues: [xMinValue, xMaxValue],
      format: value => format(toDate(value), 'dd MMM HH:mm'),
      ticksPosition: 'before',
      tickSize: 0
    },
    axisLeft: {
      tickSize: 5,
      tickValues: [
        Math.round(maxDuration / 4),
        Math.round(maxDuration / 2),
        Math.round((3 * maxDuration) / 4),
        maxDuration
      ],
      tickPadding: 10,
      legend: isDurationInHours ? 'Duration Remaining (hh:mm)' : 'Duration Remaining (mm:ss)',
      legendOffset: -55,
      legendPosition: 'middle',
      format: value => formatDuration(value, isDurationInHours)
    },
    pointSize: 10,
    pointBorderWidth: 2,
    pointBorderColor: { from: 'serieColor' },
    pointLabelYOffset: -12,
    colors: d => d.color
  }

  const width = getWidgetWidth(media, data.width !== 50)

  const chart =
    media === 'screen' ? (
      <Box height="300px" width={width as string}>
        <ResponsiveLine {...chartProps} />
      </Box>
    ) : (
      <Line height={300} width={width as number} {...chartProps} />
    )

  return (
    <MrdDashboardWidget title={data.name} fullWidth={data.width !== 50}>
      {chart}
    </MrdDashboardWidget>
  )
}

// Helper to format Y-axis duration
const formatDuration = (value: number, isDurationInHours: boolean) => {
  if (isDurationInHours) {
    const hours = Math.floor(value / (60 * 60))
    const minutes = Math.floor((value - hours * 60 * 60) / 60)
    return `${hours}:${minutes.toString().padStart(2, '0')}`
  } else {
    const minutes = Math.floor(value / 60)
    const seconds = value - minutes * 60
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }
}

const createShadedArea = (plannedDone: Date | null, actualDone: Date | null) => (props: CustomLayerProps) => {
  const theme = useTheme()
  if (!plannedDone || !actualDone) return null

  const { innerHeight: height, innerWidth, xScale, data } = props

  const actualData = data.find((series: Serie) => series.id === 'actual')
  if (!actualData || actualDone <= plannedDone) return null // No shaded area if actual <= planned

  // Compute the shaded area start and end points using xScale
  const shadedXMin = xScale(plannedDone) as number
  const shadedXMax = xScale(actualDone) as number
  const width = shadedXMax - shadedXMin // Calculate width of the shaded rect

  // Ensure the width is valid and within chart bounds
  if (shadedXMin >= shadedXMax || shadedXMin < 0 || shadedXMax > innerWidth) return null

  return (
    <rect
      data-testid="shaded-area"
      x={shadedXMin}
      y={0}
      width={width}
      height={height}
      fill={resolveColor('burn-chart-shaded-area', theme)}
      opacity={0.2}
    />
  )
}

const DashedLine = (props: any) => {
  const { series, lineGenerator, xScale, yScale } = props
  return series.map(({ id, data, color }: Serie) => {
    if (!data.length) return <Fragment key={id} />
    return (
      <path
        key={id}
        d={lineGenerator(
          data.map(d => ({
            x: xScale(d.data.x),
            y: d.data.y === null ? d.data.y : yScale(d.data.y)
          }))
        )}
        fill="none"
        stroke={color}
        style={id !== 'actual' ? { strokeDasharray: '2,1', strokeWidth: 2 } : { strokeWidth: 2 }}
      />
    )
  })
}

const createPoints = (activePoints: DataPoint[]) => (props: CustomLayerProps) => {
  const theme = useTheme()
  const { pointSize = 0, points } = props
  // active points (actual data point, etc.) needs to have a filled circle symbol
  const findActivePointMatch = (point: Point) => {
    return activePoints.some(activePoint => activePoint.x === point.data.x && activePoint.y === point.data.y)
  }
  const mappedPoints = points.map(point => {
    const borderColor = point.borderColor
    const isActivePoint = findActivePointMatch(point)
    const strokeDashArray = point.serieId === 'actual' && point.id === 'planned.1' ? '2,2' : 'none'
    return {
      id: point.id,
      symbol: Circle,
      x: point.x - pointSize / 2,
      y: point.y - pointSize / 2,
      fill: isActivePoint ? borderColor : resolveColor('white', theme),
      borderColor: borderColor,
      opacity: isActivePoint ? 1 : 'none',
      strokeDashArray
    }
  })

  return (
    <g>
      {mappedPoints.map(({ symbol, ...point }) => createElement(symbol, { ...point, size: pointSize, key: point.id }))}
    </g>
  )
}

const Circle = ({ x, y, size, opacity, borderWidth = 2, borderColor, fill, strokeDashArray }: any) => {
  return (
    <circle
      r={size / 2}
      cx={x + size / 2}
      cy={y + size / 2}
      fill={fill}
      opacity={opacity}
      strokeWidth={borderWidth}
      stroke={borderColor}
      style={{
        pointerEvents: 'none'
      }}
      strokeDasharray={strokeDashArray}
    />
  )
}
