import { useEffect, useMemo } from 'react'
import { unescape } from 'lodash'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Accordion, AccordionPanel } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { CheckboxFieldControlled, SelectField, TextInputField, UserSelectField } from 'main/components/shared/form'
import { Account, FolderGetFolder, FolderListFolder } from 'main/services/queries/types'

type FolderEditFormProps = {
  folder: FolderGetFolder
  folders: FolderListFolder[]
  account: Account
}

export type FolderEditFormType = yup.InferType<typeof validationSchema>

export const FolderEditForm = ({ folder, folders, account }: FolderEditFormProps) => {
  const { watch, setValue } = useFormContext<FolderEditFormType>()
  const { t } = useLanguage('runbooks', { keyPrefix: 'folder.editPanel' })

  const liveRunOnly = watch('live_run_only')
  const locked = watch('locked')
  const parentId = watch('parent_id')
  const isParentFolder = useMemo(() => {
    return parentId !== undefined && !parentId
  }, [parentId])

  const color = useMemo(() => folders.find(f => f.id === folder.id)?.color, [folders, folder])

  useEffect(() => {
    if (!isParentFolder) {
      if (liveRunOnly) {
        setValue('live_run_only', false)
      }
      if (locked) {
        setValue('locked', false)
      }
    }
  }, [isParentFolder, liveRunOnly, locked])

  const { parentFolders, subFolders } = useMemo(() => {
    return (folders || []).reduce<{
      parentFolders: FolderListFolder[]
      subFolders: FolderListFolder[]
    }>(
      (acc, f) => {
        if (f.id === folder.id) return { ...acc }
        acc[f.parent_id ? 'subFolders' : 'parentFolders'].push(f)
        return acc
      },
      { parentFolders: [], subFolders: [] }
    )
  }, [folders, folder])

  const canChangeParentFolder = useMemo(() => {
    if (folder.parent_id) return true
    return !subFolders.find(subFolder => subFolder.parent_id === folder.id)
  }, [folder, subFolders])

  const parentFolderOptions = useMemo(() => {
    return canChangeParentFolder ? parentFolders.map(({ name, id }) => ({ label: unescape(name), value: id })) : []
  }, [parentFolders, canChangeParentFolder])

  const orderedRoleTypes = useMemo(
    () => (folder.role_types || []).sort((a, b) => a.order - b.order),
    [folder.role_types]
  )

  return (
    <>
      <TextInputField<FolderEditFormType> name="name" label={t('nameLabel')} autoFocus />
      {canChangeParentFolder && (
        <SelectField<FolderEditFormType>
          name="parent_id"
          options={parentFolderOptions}
          label={t('parentFolderLabel')}
          data-testid="workspace-parent-folder-select"
          placeholder={t('parentFolderPlaceholder')}
        />
      )}
      <TextInputField<FolderEditFormType> name="description" label={t('descriptionLabel')} />
      {orderedRoleTypes.map((roleType, index) => {
        return (
          <UserSelectField<FolderEditFormType>
            data-testid={`role-type-${roleType.id}`}
            key={`role_types.${roleType.id}`}
            name={`role_types.${index}.users`}
            accountId={account.id}
            defaultValue={roleType.users}
            label={roleType.name}
            helpText={roleType.description || undefined}
            // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
            required={false}
          />
        )
      })}
      {isParentFolder && (
        <Accordion>
          <AccordionPanel iconColor={color} icon="info" label={t('additionalSettings.accordionLabel')}>
            <CheckboxFieldControlled<FolderEditFormType>
              name="locked"
              label={t('additionalSettings.lockedFolder.label')}
              helpText={t('additionalSettings.lockedFolder.helpText')}
            />
            <CheckboxFieldControlled<FolderEditFormType>
              name="live_run_only"
              label={t('additionalSettings.liveRunOnly.label')}
              helpText={t('additionalSettings.liveRunOnly.helpText')}
            />
          </AccordionPanel>
        </Accordion>
      )}
    </>
  )
}

export const validationSchema = yup.object({
  id: yup.number().required(),
  account_id: yup.number().required(),
  description: yup.string().nullable().notRequired(),
  name: yup.string().required(),
  parent_id: yup.number().nullable().notRequired(),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      users: yup.array().of(
        yup.object({
          role_id: yup.number(),
          id: yup.number().required()
        })
      )
    })
  ),
  live_run_only: yup.boolean(),
  locked: yup.boolean()
})
